import { useEffect, useRef, useState } from 'react';
import { ArrowDownIcon, CloseIcon } from '@floriday/floriday-icons';
import { ToggleIconButton, SnackbarManager } from '@floriday/floriday-ui';
import {
    Box,
    ButtonGroup,
    ClickAwayListener,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Popper,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { IUser } from '@features/Auth/Contracts/IUser';
import { getUserAccount } from '@features/Auth/auth';
import MarkButtonContainer from '@features/Supply/Components/Marking/MarkButton/MarkButtonContainer';
import { MarkSize } from '@features/Supply/Components/Marking/MarkingContainer/MarkingContainer';
import { namespaces } from '@root/i18n';
import { useBreakpointContext } from '@store/Providers/BreakpointProvider';
import { usePresetContext } from '@store/Providers/PresetsProvider';
import { useStyles } from './MarkButtonMenuStyles';
import AccountsMarkingList from '../AccountsMarkingList/AccountsMarkingList';

interface MarkButtonMenuProps {
    readonly size?: MarkSize;
    readonly marked: boolean;
    readonly onMark: (marked: boolean, username?: string) => void;
    readonly onSingleMark: (marked: boolean, username: string) => void;
    readonly supplyLineId?: string;
    readonly users?: string[];
    readonly comment?: string;
    readonly quantity?: number;
}

export default function MarkButtonMenu(props: MarkButtonMenuProps) {
    const { size = MarkSize.Medium, marked, onMark, onSingleMark, supplyLineId, users, comment, quantity } = props;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const anchorRef = useRef<HTMLDivElement>(null);
    const { classes } = useStyles();
    const { t } = useTranslation([namespaces.supply, namespaces.general]);

    const queryClient = useQueryClient();
    const [userAccount, setUserAccount] = useState<IUser>();
    const { isBreakpointSmall } = useBreakpointContext();
    const { selectedPreset } = usePresetContext();

    function toggleMenu() {
        if (!menuOpen) {
            queryClient.fetchQuery(['supplyLineMarkings', supplyLineId]);
        }
        setMenuOpen(open => !open);
    }

    useEffect(() => {
        async function getUser() {
            const account = await getUserAccount();
            if (account) {
                setUserAccount(account);
            }
        }

        getUser();
    }, []);

    return (
        <>
            <ButtonGroup
                variant='outlined'
                ref={anchorRef}
                className={`${classes.buttonGroup} ${size == MarkSize.Medium ? classes.medium : ''} ${size == MarkSize.Small ? classes.small : ''}`}
            >
                <MarkButtonContainer
                    onChange={(marked: boolean) => {
                        onMark(marked);

                        if (userAccount?.isSubCustomer && selectedPreset?.isReadOnly) {
                            SnackbarManager.showWarning(t('general:connectKoa.connectKOAToSeeMarkings'));
                        }

                        setMenuOpen(false);
                    }}
                    selected={!!marked}
                    comment={comment}
                    quantity={quantity}
                />
                {userAccount && !userAccount.isSubCustomer && (
                    <ToggleIconButton
                        size='small'
                        disabled={!users}
                        onClick={toggleMenu}
                        className={classes.squareIconButton}
                        data-testid='markMenuButton'
                        icon={
                            menuOpen ? (
                                <CloseIcon className={classes.toggleIcons} />
                            ) : (
                                <ArrowDownIcon className={classes.toggleIcons} />
                            )
                        }
                        isSelected={false}
                        color='primary'
                    />
                )}
            </ButtonGroup>
            {isBreakpointSmall ? (
                <Dialog fullScreen open={menuOpen}>
                    <DialogTitle className={classes.dialogTitle}>
                        {t('markButton.markForAnother')}
                        <IconButton
                            className={classes.closeButton}
                            onClick={() => {
                                setMenuOpen(false);
                            }}
                            size='large'
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <AccountsMarkingList
                            accounts={users ?? []}
                            onChange={onSingleMark}
                            supplyLineId={supplyLineId ?? ''}
                        />
                    </DialogContent>
                </Dialog>
            ) : (
                <Popper className={classes.popper} open={menuOpen} anchorEl={anchorRef.current} placement='right-start'>
                    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                        <Paper elevation={2}>
                            <Box className={classes.paperHeader}>{t('markButton.markForAnother')}</Box>
                            <AccountsMarkingList
                                accounts={users ?? []}
                                onChange={onSingleMark}
                                supplyLineId={supplyLineId ?? ''}
                            />
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            )}
        </>
    );
}
