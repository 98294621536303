import { useEffect, useMemo, useState } from 'react';
import { SnackbarManager } from '@floriday/floriday-ui';
import { DigitalAuctionSupplyType } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import { IUser } from '@features/Auth/Contracts/IUser';
import { getUserAccount } from '@features/Auth/auth';
import { MarkSize } from '@features/Supply/Components/Marking/MarkingContainer/MarkingContainer';
import { ISupplyLineMarkingCommand } from '@features/Supply/Contracts/ISupplyLineMarkingCommand';
import { useMarking } from '@features/Supply/Hooks/useMarking';
import { useSupplyLineMarkings } from '@features/Supply/Hooks/useSupplyLineMarkings';
import { useUserGroup } from '@features/Supply/Hooks/useUserGroup';
import { namespaces } from '@root/i18n';
import { addMarkingUsers, deleteMarkingUsers } from '@store/Actions/markingUsersActions';
import { useActivityTracker } from '@store/Providers/ActivityTrackerProvider';
import { useMarkingUsersContext } from '@store/Providers/MarkingUsersProvider';
import { useMarkingsContext } from '@store/Providers/MarkingsProvider';
import { usePresetContext } from '@store/Providers/PresetsProvider';
import { useUserSettings } from '@store/Providers/UserSettingsProvider';
import MarkButtonMenu from './Components/MarkButtonMenu/MarkButtonMenu';

interface MarkButtonProps {
    readonly size?: MarkSize;
    readonly supplyLineId: string;
    readonly digitalAuctionSupplyType: DigitalAuctionSupplyType;
    readonly users: string[];
    readonly comment?: string;
    readonly quantity?: number;
}

export default function MarkButtonMenuContainer(props: MarkButtonProps) {
    const { size, supplyLineId, digitalAuctionSupplyType, comment, quantity, users } = props;
    const { selectedPreset } = usePresetContext();
    const [userAccount, setUserAccount] = useState<IUser>();
    const { createMarking, deleteMarking } = useMarking();
    const { data: markings } = useSupplyLineMarkings(supplyLineId);
    const {
        state: { markings: markingsInContext },
    } = useMarkingsContext();
    const { dispatch, state } = useMarkingUsersContext();
    const [supplyLineMarking, setSupplyLineMarking] = useState<ISupplyLineMarkingCommand | undefined>();
    const markingDetail = markingsInContext.find(m => m.clockSupplyLineId === supplyLineId);
    const { data: markingGroup } = useUserGroup();
    const { trackMarkedActivity } = useActivityTracker();
    const { t } = useTranslation(namespaces.supply, { keyPrefix: 'markButton' });

    const availableUsers = useMemo(() => {
        if (!users) {
            return [];
        }

        const filteredUsers = selectedPreset?.isReadOnly ? users.filter(user => user !== userAccount?.username) : users;
        const allowedAccounts =
            digitalAuctionSupplyType === DigitalAuctionSupplyType.DigitalAuction
                ? filteredUsers.filter(account => account.includes('@'))
                : filteredUsers;

        return allowedAccounts.sort((a, b) => {
            if (hasMarking(a) && !hasMarking(b)) {
                return -1;
            }

            if (!hasMarking(a) && hasMarking(b)) {
                return 1;
            }

            return a.localeCompare(b);
        });
    }, [markings, users, userAccount]);

    const { userSettings } = useUserSettings();
    const usersWithMarking = state.markings[supplyLineId];

    const markingGroupUsers = markingGroup?.users ?? [];
    const markForGroup = markingGroupUsers.length > 0 && userSettings.markForGroup !== false;
    const markForSelf = markingGroupUsers.length === 1 && markingGroupUsers[0] === userAccount?.username;

    const allUsersInGroupMarked = markingGroupUsers.every(user =>
        usersWithMarking?.some(userName => userName === user),
    );

    useEffect(() => {
        async function getUser() {
            const account = await getUserAccount();
            if (account) {
                setUserAccount(account);
            }
        }

        getUser();
    }, []);

    function hasMarking(username: string) {
        return markings?.some(marking => marking.userName === username);
    }

    function handleMarking(marked: boolean) {
        if (markForGroup) {
            handleMarkings(marked, markingGroupUsers);
            trackMarkedActivity(markForSelf ? 'for-self' : 'for-group');
        } else if (!selectedPreset?.isReadOnly) {
            handleMarkings(marked, [userAccount?.username ?? '']);
            trackMarkedActivity('for-self');
        } else {
            SnackbarManager.showWarning(t('noAccounts'));
        }
    }

    function handleSingleMarking(marked: boolean, username: string) {
        handleMarkings(marked, [username]);
        trackMarkedActivity('for-coworker');
    }

    function handleMarkings(marked: boolean, usernames: string[]) {
        if (marked) {
            dispatch(addMarkingUsers(supplyLineId, usernames));
        } else {
            dispatch(deleteMarkingUsers(supplyLineId, usernames));
        }

        if (selectedPreset?.isReadOnly && !usernames.includes(userAccount?.username ?? '')) {
            handleRowMarking(marked, userAccount?.username);
        }

        usernames.forEach(user => {
            handleRowMarking(marked, user);
        });
    }

    function handleRowMarking(marked: boolean, username?: string) {
        if (!username) {
            setSupplyLineMarking(prevValue => ({
                id: prevValue?.id ?? '',
                clockSupplyLineId: supplyLineId ?? '',
                comment: '',
                amount: 0,
                userName: '',
                creationUserName: '',
            }));
        }

        if (marked) {
            createMarking.mutate({
                supplyLineMarkingCommand: {
                    id: supplyLineMarking?.id,
                    clockSupplyLineId: supplyLineId ?? '',
                },
                username,
            });
        } else {
            deleteMarking.mutate({
                clockSupplyLineId: supplyLineId ?? '',
                supplyLineMarkingCommand: !username ? (supplyLineMarking as ISupplyLineMarkingCommand) : undefined,
                username,
            });
        }
    }

    useEffect(() => {
        if (markings && markings.length > 0) {
            dispatch(
                addMarkingUsers(
                    supplyLineId,
                    markings.map(marking => marking.userName),
                ),
            );
        }

        if (markingDetail) {
            setSupplyLineMarking(markingDetail);
        } else {
            setSupplyLineMarking({
                id: '',
                clockSupplyLineId: '',
                comment: '',
                amount: 0,
                userName: '',
                creationUserName: '',
            });
        }
    }, [markings, userAccount]);

    return (
        <MarkButtonMenu
            size={size}
            marked={markForGroup && !markForSelf ? allUsersInGroupMarked : !!markingDetail}
            onMark={handleMarking}
            onSingleMark={handleSingleMarking}
            supplyLineId={supplyLineId}
            users={availableUsers}
            comment={comment}
            quantity={quantity}
        />
    );
}
