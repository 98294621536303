import { SquareIconButton } from '@floriday/floriday-ui';
import { Box } from '@mui/material';
import { ClockSupplyLine } from '@rfh-digital-auction/rfh-auction-preparation/tsc-output/Rfh.AuctionPreparation.Client';
import { useTranslation } from 'react-i18next';

import OfflineMarkButtonMenu from '@features/Supply/Components/Marking/MarkButtonMenu/Components/MarkButtonMenu/MarkButtonMenu';
import MarkButtonMenu from '@features/Supply/Components/Marking/MarkButtonMenu/MarkButtonMenuContainer';
import { useNavigatorOnLine } from '@root/Hooks/useNavigatorOnLine';
import { FloridayPriceType, formatPrice, namespaces } from '@root/i18n';
import CartIcon from '@resources/Icons/CartIcon';
import { useActivityTracker } from '@store/Providers/ActivityTrackerProvider';
import { usePresetContext } from '@store/Providers/PresetsProvider';
import { getExplorerLink } from '@utils/ExplorerUtils';
import { toPackages } from '@utils/QuantityFormatter';
import { useStyles } from './ScanRowStyles';

export interface IScanRowProps {
    readonly clockSupplyLine?: ClockSupplyLine;
    readonly characterIndex: string;
    readonly markingAccounts?: string[];
    readonly onOfflineMark?: (marked: boolean) => void;
    readonly offlineMarked?: boolean;
}

export default function ScanRow(props: IScanRowProps) {
    const { clockSupplyLine, markingAccounts, characterIndex, onOfflineMark = () => {}, offlineMarked = false } = props;
    const { classes } = useStyles();

    const { selectedPreset } = usePresetContext();
    const { trackPresaleBuyRedirectedActivity } = useActivityTracker();
    const isOnline = useNavigatorOnLine();
    const { t } = useTranslation(namespaces.supply);

    const buyLink = getExplorerLink(
        clockSupplyLine?.clockPresalesSupplyLineId ?? '',
        selectedPreset?.organizationId ?? '',
    );

    function getPreSalePrice(): FloridayPriceType {
        return {
            currency: clockSupplyLine?.preSalePriceCurrency,
            value: clockSupplyLine?.preSalePriceValue,
        };
    }

    function getQuantity() {
        const piecesPerPackage = clockSupplyLine?.piecesPerPackage ?? 1;

        if (clockSupplyLine?.clockPresalesSupplyLineId) {
            const preSaleCurrentNumberOfPieces = clockSupplyLine.preSaleCurrentNumberOfPieces ?? 0;
            const currentNumberOfPieces = clockSupplyLine.currentNumberOfPieces ?? 0;

            return `${toPackages(preSaleCurrentNumberOfPieces, piecesPerPackage)} / ${toPackages(currentNumberOfPieces, piecesPerPackage)}`;
        }

        return toPackages(clockSupplyLine?.currentNumberOfPieces ?? 0, piecesPerPackage);
    }

    return (
        <Box className={classes.row} gap={0.5} data-testid='scanRow'>
            <Box data-testid='characterIndex' className={classes.rowStart}>
                {characterIndex}
                {isOnline && clockSupplyLine ? (
                    <MarkButtonMenu
                        supplyLineId={clockSupplyLine.id ?? ''}
                        digitalAuctionSupplyType={clockSupplyLine.digitalAuctionSupplyType}
                        users={markingAccounts ?? []}
                    />
                ) : (
                    <OfflineMarkButtonMenu onMark={onOfflineMark} onSingleMark={() => {}} marked={offlineMarked} />
                )}
            </Box>
            <Box className={classes.rowMiddle}>
                <Box data-testid='product' className={clockSupplyLine ? classes.productName : classes.unavailableText}>
                    {clockSupplyLine ? clockSupplyLine.productName : t('scan.batchUnavailable')}
                </Box>
            </Box>
            <Box className={classes.rowEnd} data-testid='quantity'>
                {clockSupplyLine && (
                    <>
                        <Box className={classes.quantityPrice}>
                            <Box>{getQuantity()}</Box>
                            {clockSupplyLine.clockPresalesSupplyLineId && (
                                <Box data-testid='preSalePrice'>{formatPrice(getPreSalePrice())}</Box>
                            )}
                        </Box>
                        {isOnline && clockSupplyLine?.clockPresalesSupplyLineId && (
                            <SquareIconButton
                                className={classes.buyButton}
                                data-testid='buyButtonScan'
                                onClick={e => {
                                    e.stopPropagation();
                                    const userAgent = navigator.userAgent.toLowerCase();
                                    trackPresaleBuyRedirectedActivity();
                                    window.open(buyLink, userAgent.includes('iphone') ? '_blank' : 'floriday');
                                }}
                            >
                                <CartIcon />
                            </SquareIconButton>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
}
